.full-page-loader {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  text-align: center;
  margin: 0px auto;
}

.full-page-loader > img {
  animation: 1.8s infinite heartbeat;
  margin: 0px auto;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&family=Roboto:wght@100;300;400;500;700;900&display=swap");

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  outline: none !important;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #fff;
  color: #555555;
  font-size: 16px;
  line-height: 27px;
  font-family: "Poppins", sans-serif;
}

button {
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
  color: #262626;
  transition-duration: 0.5s;
  cursor: pointer;
}

h2 {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 0.5rem;
}

a:hover {
  color: #ab1818;
  text-decoration: none;
}

.whatsapp {
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  z-index: 9999;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  cursor: pointer;
}
.whatsapp > img {
  width: 100%;
  height: 100%;
}

.control-dots > li {
  width: 10px;
  height: 10px;
}

.container {
  width: 1200px;
  height: auto;
  max-width: 100%;
  margin: 0px auto;
  padding: 0px;
}

.header {
  width: 100%;
  height: 92vh;
  position: relative;
}
.header > h1 {
  opacity: 0;
  position: absolute;
}
.header > .header-logo-container {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 40px 40px;
  padding-right: 65px;
  background-color: #fff;
  z-index: 3;
}
.header > .header-logo-container > img {
  width: 220px;
  height: auto;
  z-index: 4;
  margin-bottom: -8px;
}
.header > .header-logo-container:before {
  content: "";
  width: 90px;
  height: 100%;
  position: absolute;
  top: 0;
  right: -40px;
  -webkit-transform: skewX(30deg);
  transform: skewX(30deg);
  background: #fff;
  z-index: -1;
}
.header > .header-logo-container:after {
  content: "";
  width: 90px;
  height: 100%;
  position: absolute;
  top: 0;
  right: -50px;
  -webkit-transform: skewX(30deg);
  transform: skewX(30deg);
  background: #d9d7d6;
  z-index: -2;
}

.header > .header-slider-container {
  width: 100%;
  height: 100%;
  z-index: -1;
}
.header > .header-slider-container > .carousel-root {
  width: 100%;
  height: 100%;
}
.header > .header-slider-container > .carousel-root > .carousel-slider {
  width: 100%;
  height: 100%;
}
.header
  > .header-slider-container
  > .carousel-root
  > .carousel-slider
  > .carousel-status {
  display: none;
}
.header > .header-slider-container > .carousel-root > .carousel-slider {
  width: 100%;
  height: 100%;
}
.header > .header-slider-container > .carousel-root > .carousel:nth-child(2) {
  display: none;
}

.header
  > .header-slider-container
  > .carousel-root
  > .carousel-slider
  > button {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.header
  > .header-slider-container
  > .carousel-root
  > .carousel-slider
  > button:hover {
  background: none;
}

.header
  > .header-slider-container
  > .carousel-root
  > .carousel-slider
  > .slider-wrapper {
  width: 100%;
  height: 100%;
}
.header
  > .header-slider-container
  > .carousel-root
  > .carousel-slider
  > .slider-wrapper
  > .slider {
  height: 100%;
}

.header
  > .header-slider-container
  > .carousel-root
  > .carousel-slider
  > .slider-wrapper
  > .slider
  > .slide {
  height: 100%;
}
.header
  > .header-slider-container
  > .carousel-root
  > .carousel-slider
  > .slider-wrapper
  > .slider
  > .slide
  > div {
  width: 100%;
  height: 100%;
}

.header
  > .header-slider-container
  > .carousel-root
  > .carousel-slider
  > .slider-wrapper
  > .slider
  > .slide
  > div
  > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header
  > .header-slider-container
  > .carousel-root
  > .carousel-slider
  > .slider-wrapper
  > .slider
  > .slide
  > div
  > .carousel-text-container {
  width: 100%;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 3rem;
}

.header
  > .header-slider-container
  > .carousel-root
  > .carousel-slider
  > .slider-wrapper
  > .slider
  > .slide
  > div
  > .carousel-text-container
  > h2 {
  color: #ffffff;
  font-size: 45px;
  line-height: 60px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.header
  > .header-slider-container
  > .carousel-root
  > .carousel-slider
  > .slider-wrapper
  > .slider
  > .slide
  > div
  > .carousel-text-container
  > h2
  > span {
  color: #ab1818;
}

.header
  > .header-slider-container
  > .carousel-root
  > .carousel-slider
  > .slider-wrapper
  > .slider
  > .slide
  > div
  > .carousel-text-container
  > p {
  color: #fefefe;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.01em;
  margin-top: 35px;
  margin-bottom: 40px;
}
.header
  > .header-slider-container
  > .carousel-root
  > .carousel-slider
  > .control-dots
  > li {
  border-radius: 0px;
  width: 30px;
  height: 3px;
  margin: 3px;
}

.navigation-menu-mobile {
  display: none;
}
.navigation-menu-mobile > button > svg {
  width: 30px;
  height: 30px;
  color: #262626;
}

.navigation-menu {
  width: 100%;
  height: 8vh;
  border-bottom: solid 1px #e7e7e7;
  display: flex;
  transition-duration: 0.5s;
}
.navigation-menu > section {
  display: flex;
  flex-direction: row;
  align-self: stretch;
}
.navigation-menu > section > nav {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex: 1;
}
.navigation-menu > section > nav > a {
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: solid 3px #fff;
  font-weight: 600;
  font-size: 15px;
  z-index: 3;
  text-align: center;
  color: #555;
}
.navigation-menu > section > nav > a:hover {
  border-top: solid 3px #ab1818;
}
.navigation-menu > section > nav > .active {
  border-top: solid 3px #ab1818;
}

.navigation-menu-contact {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  border-top: solid 3px #fff;
  min-width: 350px;
}
.navigation-menu-contact:hover {
  border-top: solid 3px #ab1818;
}
.navigation-menu-contact > section {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.navigation-menu-contact > section > span {
  font-size: 12px;
  margin-bottom: 5px;
}
.navigation-menu-contact > section > span > span {
  color: #ab1818;
  font-weight: 700;
}
.navigation-menu-contact > section > a {
  font-size: 15px !important;
  font-weight: 600 !important;
  color: #555555;
}
.navigation-menu-contact-mobile {
  display: none;
  grid-template-columns: auto auto;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  background-color: #fff;
  padding-left: 20px;
  padding-right: 20px;
}

.navigation-menu-contact > section > a:hover {
  color: #ab1818;
}
.navigation-menu-contact > section > a > svg {
  color: #ab1818;
  margin-right: 5px;
}

.navigation-menu-fixed {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0px 0px 16px rgb(0 0 0 / 32%);
  height: auto;
  animation: fadein 1s;
  z-index: 9;
}
.navigation-menu-fixed > section > nav > .active {
  border-top: solid 3px #ab1818;
  color: #ab1818;
}
.navigation-menu-fixed > .container > .navigation-menu-contact > section > a {
  padding-bottom: 5px;
}

.navigation-menu-fixed > section > nav > a {
  padding-left: 10px;
  padding-right: 10px;
}
.navigation-menu-fixed-logo-container {
  display: flex;
  align-items: center;
  margin-right: 5px;
  opacity: 0;
}
.navigation-menu-fixed-logo-container > img {
  width: 56px !important;
  height: 50px !important;
}
.navigation-menu-fixed > section > .navigation-menu-fixed-logo-container {
  opacity: 1;
}
.navigation-menu-fixed-button {
  display: none;
}
.navigation-menu-fixed-button > svg {
  width: 30px;
  height: 30px;
  color: #262626;
}

footer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  scroll-margin: 5rem;
}

footer > .bottom {
  padding: 28px 0px;
  font-size: 14px;
  font-weight: 600;
}
footer > iframe {
  width: 100%;
  height: 500px;
  border: 0;
}
footer > .head {
  border-bottom: solid 3px #ab1818;
  background: #262626;
  padding: 50px 0;
  text-align: justify;
  color: #fff;
}

footer > .head > .container > .footer-head-container {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  align-items: center;
  gap: 1rem;
}

footer > .head > .container > .footer-head-container > section {
  display: flex;
  flex-direction: column;
}

footer > .head > .container > .footer-head-container > section > h3 {
  font-weight: 600;
  font-size: 30px;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

footer > .head > .container > .footer-head-container > section > p {
  font-size: 15px;
  line-height: 28px;
  letter-spacing: 0.01em;
  margin-top: 8px;
  margin-bottom: 1rem;
}

footer > .head > .container > .footer-head-container > section > a {
  color: #fff;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
footer > .head > .container > .footer-head-container > section > a > * {
  cursor: pointer;
}
footer > .head > .container > .footer-head-container > section > a:hover {
  color: #ab1818;
}
footer
  > .head
  > .container
  > .footer-head-container
  > section
  > a
  > label
  > h4 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 1rem;
}
footer > .head > .container > .footer-head-container > section > a > label {
  font-size: 17px;
}
footer > .head > .container > .footer-head-container > section > a > svg {
  margin-right: 1rem;
  width: 33px;
  height: 33px;
}

footer > .head > .container > .footer-head-container > section > a > label {
}

.brands {
  width: 1280px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
}
.brands > h2 {
  line-height: 1.2;
  color: #262626;
}
.brands > p {
  margin-top: 21px;
  color: #555555;
  margin: 0;
  font-size: 18px;
  line-height: 28px;
}
.brands > .brands-slider-container {
  width: 100%;
  height: auto;
  margin-top: 50px;
  position: relative;
}
.brands > .brands-slider-container > .carousel-root > .carousel-slider {
}
.brands > .brands-slider-container > .carousel-root > .carousel:nth-child(2) {
  display: none !important;
}
.brands
  > .brands-slider-container
  > .carousel-root
  > .carousel-slider
  > button::before {
  border-right-color: #262626;
  border-left-color: #262626;
}
.brands
  > .brands-slider-container
  > .carousel-root
  > .carousel-slider
  > button:hover {
  background: none;
}
.brands
  > .brands-slider-container
  > .carousel-root
  > .carousel-slider
  > .slider-wrapper
  > .slider
  > .slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.brands
  > .brands-slider-container
  > .carousel-root
  > .carousel-slider
  > .slider-wrapper
  > .slider
  > .slide
  > div
  > img {
  height: auto !important;
  max-height: 80px !important;
  max-width: 200px !important;
  width: auto !important;
  align-self: center;
}

.brands
  > .brands-slider-container
  > .carousel-root
  > .carousel-slider
  > .slider-wrapper {
  margin-bottom: 2.5rem !important;
}

.brands
  > .brands-slider-container
  > .carousel-root
  > .carousel-slider
  > .carousel-status {
  display: none;
}

.brands
  > .brands-slider-container
  > .carousel-root
  > .carousel-slider
  > .slider-wrapper
  > .slider
  > .slide
  > .slide-fix-div {
  display: flex;
  flex-direction: row;
  gap: 3rem;
}
.brands
  > .brands-slider-container
  > .carousel-root
  > .carousel-slider
  > .control-dots
  > li {
  box-shadow: none;
  background-color: rgb(214, 214, 214);
  opacity: 1;
}
.brands
  > .brands-slider-container
  > .carousel-root
  > .carousel-slider
  > .control-dots
  > .selected {
  background-color: #869791;
}

.combiComponentsRef {
  scroll-margin: 3rem;
}

.combiComponents {
  width: 100%;
  height: auto;
  padding: 50px 0px;
  text-align: center;
  background-image: url("./assets/images/combi-bg.webp");
  background-size: 100% 100%;
}
.combiComponents > h2 {
  color: #fff;
  margin-bottom: 90px;
  line-height: 1.2;
}

.combiComponents > .container {
  width: 1280px;
}
.combiComponents > .container > .combiComponents-wrapper {
}

.combiComponents-wrapper-wrap {
  background-color: #252525 !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}
.combiComponents-wrapper-wrap > h4 {
  text-align: center;
  font-size: 20px;
  color: #fff;
  margin-top: 4rem;
}

.combiComponents-wrapper-wrap-img-container {
  width: 230px !important;
  height: 230px !important;
  border-radius: 100%;
  border: solid 5px #ab1818;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e9e6e6;
}
.combiComponents-wrapper-wrap-img-container > img {
  width: auto !important;
  height: auto !important;
  max-width: 100% !important;
  max-height: 100% !important;
}

.combiComponents-wrapper > .carousel-root > .carousel:nth-child(2) {
  display: none !important;
}

.combiComponents-wrapper > .carousel-root > .carousel-slider > button:hover {
  background: none;
}

.combiComponents-wrapper
  > .carousel-root
  > .carousel-slider
  > .carousel-status {
  display: none;
}

.combiComponents-wrapper > .carousel-root > .carousel-slider > .slider-wrapper {
  margin-bottom: 4rem;
}

.combiComponents-wrapper
  > .carousel-root
  > .carousel-slider
  > .slider-wrapper
  > .slider
  > .slide
  > .slide-fix-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.combiComponents-wrapper
  > .carousel-root
  > .carousel-slider
  > .slider-wrapper
  > .slider
  > .slide
  > .slide-fix-div
  > section {
  border-right: solid 1px #403f3f;
  padding-right: 5rem;
  padding-left: 5rem;
}
.combiComponents-wrapper
  > .carousel-root
  > .carousel-slider
  > .slider-wrapper
  > .slider
  > .slide
  > .slide-fix-div
  > section:last-child {
  border-right: 0px;
}

.usefulInformation {
  padding: 50px 0px;
  text-align: center;
  padding-top: 10rem;
  scroll-margin: 5rem;
}
.usefulInformation > h2 {
  color: #262626;
  line-height: 1.2;
}
.usefulInformation > p {
  font-size: 18px;
  line-height: 28px;
  margin-top: 21px;
}

.usefulInformation-accordion-container {
  margin-top: 45px;
  text-align: justify;
  background-color: #f6f6f6;
  padding: 10px 25px;
  transition: all 0.4s ease;
}
.usefulInformation-accordion-container > section {
  transition-duration: 0.5s;
}

.usefulInformation-accordion-container > section > button {
  font-size: 18px;
  font-weight: 600;
  color: #262626;
  background: none;
  border: 0px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-top: 1px solid #e1e1e1;
  padding: 22px 0px;
  line-height: 1.2;
  transition-duration: 0.5s;
}
.usefulInformation-accordion-container > section:first-child > button {
  border-top: 0px;
}

.usefulInformation-accordion-container > section > button > span {
  border: 2px solid #999;
  border-radius: 50%;
  color: #999;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.usefulInformation-accordion-container > section > button > span > svg {
  height: 10px;
  width: 10px;
}
.usefulInformation-accordion-container > section {
  width: 100%;
  height: auto;
}

.usefulInformation-accordion-container > section > p {
  width: 100%;
  height: 0px;
  text-align: justify;
  overflow: hidden;
  transition-duration: 0.5s;
  opacity: 0;
}
.usefulInformation-accordion-container > .active > p {
  height: auto;
  padding-bottom: 22px;
  opacity: 1;
}
.usefulInformation-accordion-container > .active > button {
  color: #ab1818;
}

.usefulInformation-accordion-container > .active > button > span {
  border: 2px solid #262626;
  color: #262626;
}

.about {
  width: 100%;
  height: auto;
  background-image: url("./assets/images/about-bg.webp");
  background-attachment: fixed;
  background-size: cover;
}

.about > .about-container {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 50px 0;
  color: #fff;
}

.about > .about-container > .container {
  text-align: center;
}
.about > .about-container > .container > p {
  font-size: 16px;
  line-height: 27px;
  margin-bottom: 50px;
  margin-top: 75px;
  white-space: pre-line;
  text-align: justify;
}
.about > .about-container > .container > p > b {
  font-weight: bold;
}
.about > .about-container > .container > h2 {
  line-height: 1.2;
}
.about > .about-container > .container > p > h3 {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}
.about > .about-container > .container > p > h3:nth-child(2) {
  margin-top: 2rem;
}
.about > .about-container > .container > p > ul {
  padding-left: 3rem;
}

.about-wrapper {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: -10rem;
  text-align: justify;
}

.about-wrapper > .about-wrapper-wrap {
  padding: 41px 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}

.about-wrapper > .about-wrapper-wrap > svg {
  width: 60px;
  height: 60px;
}

.about-wrapper > .about-wrapper-wrap > section > h4 {
  font-size: 20px;
  font-weight: 600;
}

.about-wrapper > .about-wrapper-wrap > section > p {
  font-size: 15px;
  line-height: 24px;
  margin-top: 15px;
}

.supportedServices {
  width: 100%;
  height: auto;
  padding: 50px 0;
  scroll-margin: 5rem;
}

.supportedServices > .container {
  text-align: center;
}
.supportedServices > .container > h2 {
  color: #262626;
  line-height: 1.2;
}
.supportedServices > .container > p {
  margin-top: 21px;
  color: #555555;
  margin: 0;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 70px;
}
.supportedServices-wrapper {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.5rem;
  margin-bottom: 15rem;
}
.supportedServices-wrapper > .supportedServices-wrapper-wrap {
  width: 368px;
  max-width: 1fr;
  height: 250px;
  text-align: center;
}

.supportedServices-wrapper
  > .supportedServices-wrapper-wrap
  > .supportedServices-wrapper-wrap-content {
  width: 90%;
  height: auto;
  margin: 0px auto;
  margin-top: 14rem;
  color: #fff;
  background-color: #ab1818;
  padding: 50px 35px;
  padding-bottom: 0px;
  position: relative;
  transition-duration: 0.5s;
}
.supportedServices-wrapper
  > .supportedServices-wrapper-wrap
  > .supportedServices-wrapper-wrap-content:hover {
  box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.2);
  padding-bottom: 25px;
  background-color: #fff;
}

.supportedServices-wrapper
  > .supportedServices-wrapper-wrap
  > .supportedServices-wrapper-wrap-content
  > a {
  opacity: 0;
  font-size: 15px;
  font-weight: 600;
  color: #ab1818;
  transition-duration: 0.5s;
  display: block;
}

.supportedServices-wrapper
  > .supportedServices-wrapper-wrap
  > .supportedServices-wrapper-wrap-content:hover
  > a {
  opacity: 1;
  margin-top: 22px;
}
.supportedServices-wrapper
  > .supportedServices-wrapper-wrap
  > .supportedServices-wrapper-wrap-content:hover
  > a:hover {
  color: #555555;
}
.supportedServices-wrapper
  > .supportedServices-wrapper-wrap
  > .supportedServices-wrapper-wrap-content:hover
  > h4 {
  color: #262626;
}
.supportedServices-wrapper
  > .supportedServices-wrapper-wrap
  > .supportedServices-wrapper-wrap-content:hover
  > p {
  color: #555555;
}

.supportedServices-wrapper
  > .supportedServices-wrapper-wrap
  > .supportedServices-wrapper-wrap-content
  > h4 {
  margin-top: 25px;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 20px;
}

.supportedServices-wrapper
  > .supportedServices-wrapper-wrap
  > .supportedServices-wrapper-wrap-content
  > p {
  text-align: justify;
  line-height: 24px;
  font-size: 15px;
}

.supportedServices-wrapper
  > .supportedServices-wrapper-wrap
  > .supportedServices-wrapper-wrap-content
  > span {
  margin-top: -5rem;
  display: block;
}
.supportedServices-wrapper
  > .supportedServices-wrapper-wrap
  > .supportedServices-wrapper-wrap-content
  > span
  > svg {
  background-color: #fff;
  color: #262626;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 1.5rem;
  transition-duration: 0.5s;
}

.supportedServices-wrapper
  > .supportedServices-wrapper-wrap
  > .supportedServices-wrapper-wrap-content:hover
  > span
  > svg {
  background-color: #ab1818;
  color: #fff;
}

.loading {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ab1818;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.loading > .loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loading > .loader > section {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loading > .loader > section:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@media screen and (max-width: 1071px) {
  h2 {
    font-weight: 600;
    font-size: 32px;
  }

  .container {
    width: 100%;
    padding: 0px;
  }

  .header {
    height: 100vh;
  }

  .header-logo-container {
    display: none;
  }

  .navigation-menu {
    height: 10vh;
  }
  .navigation-menu > .container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navigation-menu > .container > nav {
    display: none;
  }
  .navigation-menu-contact {
    display: none;
  }
  .navigation-menu-contact-mobile {
    display: grid;
    padding: 5px 0px;
  }

  .navigation-menu-fixed {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 32%);
    height: auto;
    animation: fadein 1s;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 0.3rem;
  }
  .navigation-menu-fixed-logo-container {
    margin-right: 0px;
    opacity: 1;
  }
  .navigation-menu-fixed-logo-container > img {
    width: 56px !important;
    height: 50px !important;
    padding: 5px;
  }
  .navigation-menu-fixed-button {
    border: 0;
    cursor: pointer;
    display: block !important;
    position: absolute;
    left: 0.5rem;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background: none;
    padding: 1rem;
    outline: none !important;
  }
  .navigation-menu-fixed-button > i {
    font-size: 30px;
    font-weight: 900;
    color: #262626;
  }

  .navigation-menu-mobile {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 99;
    display: flex !important;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .navigation-menu-mobile > button {
    position: fixed;
    top: 2rem;
    right: 2rem;
    background: none;
    border: 0;
    background: none;
    outline: none;
  }
  .navigation-menu-mobile > button > i {
    color: #262626;
    font-size: 30px;
  }

  .navigation-menu-mobile > img {
    width: 250px;
    height: 70px;
    object-fit: cover;
  }
  .navigation-menu-mobile > nav {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
  }
  .navigation-menu-mobile > nav > a {
    font-size: 20px;
    font-weight: 600;
    padding: 15px 0px;
    cursor: pointer;
    z-index: 99999;
  }
  .navigation-menu-mobile > nav > .active {
    color: #ab1818;
  }

  .supportedServices {
    width: 100%;
    padding: 50px 20px;
  }
  .supportedServices-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 0rem;
  }
  .supportedServices-wrapper > .supportedServices-wrapper-wrap {
    width: 100%;
    max-width: 100%;
    height: 250px !important;
    text-align: center;
    margin-bottom: 17rem;
  }

  .about {
    background-image: url("./assets/images/about-bg.webp");
    background-attachment: fixed;
    background-size: auto;
  }
  .about > .about-container {
    padding: 50px 20px;
  }
  .about > .about-container > .container > p {
    margin-top: 50px;
  }
  .about > .about-container > .container > h2 {
    margin: 0px;
  }
  .about > .about-container > .container > .about-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 0rem;
  }

  .usefulInformation {
    padding: 50px 20px;
  }
  .usefulInformation-accordion-container > section > button {
    text-align: left;
  }

  .brands {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 50px 20px;
  }
  .brands .brands-slider-container {
    margin: 50px 0px;
  }

  .brands
    > .brands-slider-container
    > .carousel-root
    > .carousel-slider
    > .slider-wrapper {
    margin-bottom: 5rem !important;
  }
  .brands
    > .brands-slider-container
    > .carousel-root
    > .carousel-slider
    > button {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .combiComponents-wrapper
    > .carousel-root
    > .carousel-slider
    > .slider-wrapper {
    margin-bottom: 6rem;
  }
  .combiComponents-wrapper > .carousel-root > .carousel-slider > button {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  footer > .head {
    padding: 50px 20px;
  }

  footer > .head > .container > .footer-head-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  footer > .head > .container > .footer-head-container > section > p {
    margin-bottom: 0px;
  }
  footer > .head > .container > .footer-head-container > section > h3 {
    text-align: center;
  }
  footer > .bottom {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
  }
}
